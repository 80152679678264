import { cx } from "class-variance-authority";
import { useKeenSlider } from "keen-slider/react";
import * as React from "react";
import { ActionTrigger } from "../Action";
import type { PricingCarouseProps } from "./types";

export function PricingCarousel({
  title,
  filters,
  offers,
}: PricingCarouseProps) {
  const [currentFilterId, setCurrentFilterId] = React.useState(() => {
    return filters.length > 0
      ? filters.find((filter) => !!filter.isDefault)?.filterId ||
          filters[0].filterId
      : null;
  });

  const [currentSlide, setCurrentSlide] = React.useState(0);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    selector: "[data-offer-item]",
    initial: 0,
    loop: true,
    mode: "free-snap",

    breakpoints: {
      "(max-width: 767.5px)": {
        slides: {
          origin: "center",
          perView: 1,
          spacing: 15,
        },
      },

      "(min-width: 768px)": {
        slides: {
          origin: "center",
          perView: 1.2,
          spacing: 15,
        },
      },
      "(min-width: 992px)": {
        loop: false,
        slides: {
          origin: "auto",
          perView: 3,
          spacing: 32,
        },
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel);
    },
  });

  const { filteredOffers, filteredOffersHasBasePrice } = React.useMemo(() => {
    const filteredOffers = currentFilterId
      ? offers.filter((offer) => offer.filterId === currentFilterId)
      : offers;

    const filteredOffersHasBasePrice = filteredOffers.some(
      (offer) => offer.basePrice
    );

    return { filteredOffers, filteredOffersHasBasePrice };
  }, [currentFilterId, offers]);

  const onThumbsClick = React.useCallback(
    (idx: number) => {
      setCurrentSlide(idx);
      instanceRef.current?.moveToIdx(idx);
    },
    [instanceRef]
  );

  // Odśwież slider po zmianie ofert
  React.useEffect(() => {
    if (instanceRef.current) {
      instanceRef.current.update();

      const defaultOfferIndex =
        filteredOffers.findIndex((offer) => !!offer.isDefault) || 0;
      setCurrentSlide(defaultOfferIndex);
    }
  }, [filteredOffers, instanceRef]);

  return (
    <div className="container py-7 from-desktop:py-12">
      {title && (
        <h2 className="text-xl font-medium pb-8 text-center text-secondary">
          {title}
        </h2>
      )}
      {filters.length > 0 && (
        <div className="flex items-center justify-center w-full pb-6 from-desktop:pb-12">
          <div className="border-2 border-secondary rounded-[32px] p-1 from-desktop:p-[6px] flex justify-center items-start min-h-12 from-desktop:min-h-16 w-full max-w-[600px]">
            {filters.map(({ filterId, name, sticker }) => {
              const isActive = filterId === currentFilterId;
              return (
                <div
                  key={filterId}
                  onClick={() => {
                    setCurrentFilterId(filterId);
                  }}
                  className={cx(
                    "h-full min-h-10 from-desktop:min-h-[60px] w-full from-desktop:w-1/2 rounded-[38px] py-1 px-2 from-desktop:px-4 from-desktop:py-2 flex items-center justify-center gap-3 cursor-pointer",
                    isActive
                      ? "bg-secondary text-primary"
                      : "bg-primary text-secondary"
                  )}
                >
                  <span className="text-base from-desktop:text-xl leading-none whitespace-nowrap">
                    {name}
                  </span>
                  {sticker && (
                    <span className="rounded-2xl p-2 bg-action text-active text-base from-desktop:text-xl whitespace-nowrap leading-none">
                      {sticker}
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="flex flex-col gap-5">
        <div
          ref={sliderRef}
          className="keen-slider flex w-full relative overflow-hidden"
        >
          {filteredOffers.map(
            ({
              planName,
              subTitle,
              id,
              description,
              action,
              features,
              basePrice,
              price,
            }) => {
              return (
                <div
                  key={id}
                  data-offer-item
                  className="text-primary flex flex-col gap-8 p-8 bg-secondary min-w-[352px]"
                >
                  <div>
                    <h2 className="text-3xl">{planName}</h2>
                    {subTitle && <p className="text-base">{subTitle} </p>}
                  </div>
                  <div className="flex flex-col">
                    {(basePrice || filteredOffersHasBasePrice) && (
                      <>
                        {basePrice ? (
                          <div className="text-base line-through whitespace-nowrap leading-none">
                            {basePrice.value}{" "}
                            {basePrice.suffix && (
                              <span>{basePrice.suffix}</span>
                            )}
                          </div>
                        ) : (
                          <div className="text-base whitespace-nowrap leading-none">
                            &nbsp;
                          </div>
                        )}
                      </>
                    )}
                    <div className="flex items-end">
                      <span className="text-3xl text-action font-bold">
                        {price.value}
                      </span>
                      {price.suffix && (
                        <span className="text-base text-primary font-bold">
                          {price.suffix}
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <p className="text-base font-semibold">{description}</p>
                    {features.length > 0 && (
                      <ul className="flex flex-col gap-3">
                        {features.map(({ name }) => {
                          return (
                            <li
                              className="p-2 border-b border-b-primary"
                              key={name}
                            >
                              {name}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                  {action && (
                    <div className="w-full mt-auto">
                      <ActionTrigger
                        className="w-full"
                        action={{
                          ...action,
                          variant: "PRIMARY",
                          size: "SMALL",
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            }
          )}
        </div>
        <div
          className={cx(
            "gap-2 hidden ",
            filteredOffers.length <= 3
              ? "from-desktop:hidden"
              : "from-desktop:flex"
          )}
        >
          {Array.from({
            length:
              filteredOffers.length > 3
                ? Math.ceil(filteredOffers.length / 3)
                : filteredOffers.length,
          }).map((_, index) => {
            const isActive = index === currentSlide;
            return (
              <div
                key={index}
                onClick={() => {
                  onThumbsClick(index);
                }}
                className={cx(
                  "flex-1 h-[10px] cursor-pointer",
                  isActive ? "bg-action" : "bg-secondary"
                )}
              />
            );
          })}
        </div>
        <div className={cx("gap-2 flex from-desktop:hidden")}>
          {Array.from({
            length: filteredOffers.length,
          }).map((_, index) => {
            const isActive = index === currentSlide;
            return (
              <div
                key={index}
                onClick={() => {
                  onThumbsClick(index);
                }}
                className={cx(
                  "flex-1 h-[10px] cursor-pointer",
                  isActive ? "bg-action" : "bg-secondary"
                )}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
