import { cx } from "class-variance-authority";
import { useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import * as React from "react";
import { QuotesProps } from "./types";

export function Quotes({
  initialIndex,
  items,
  title,
  autoPlay = false,
  animationSpeed = 30000,
}: QuotesProps) {
  const initSlide = React.useMemo(() => {
    if (initialIndex && initialIndex > 0 && initialIndex < items.length) {
      return initialIndex;
    }
    return 0;
  }, [initialIndex, items.length]);
  const animation = React.useMemo(() => {
    return { duration: animationSpeed, easing: (t: number) => t };
  }, [animationSpeed]);

  const [currentSlide, setCurrentSlide] = React.useState(initSlide);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    selector: "[data-quotes-item]",
    initial: initSlide,
    loop: true,
    mode: "free-snap",
    breakpoints: {
      "(max-width: 499.5px)": {
        slides: {
          origin: "center",
          perView: 1.2,
          spacing: 10,
        },
      },
      "(min-width: 500px)": {
        slides: {
          origin: "center",
          perView: 1.2,
          spacing: 20,
        },
      },
      "(min-width: 992px)": {
        slides: {
          origin: "center",
          perView: 1.2,
          spacing: 32,
        },
      },
    },
    created(slider) {
      if (!autoPlay) {
        return;
      }
      slider.moveToIdx(5, true, animation);
    },
    updated(slider) {
      if (!autoPlay) {
        return;
      }
      slider.moveToIdx(slider.track.details.abs + 5, true, animation);
    },
    animationEnded(slider) {
      if (!autoPlay) {
        return;
      }
      slider.moveToIdx(slider.track.details.abs + 5, true, animation);
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel);
    },
  });

  const onThumbClick = React.useCallback(
    (idx: number) => {
      setCurrentSlide(idx);
      instanceRef.current?.moveToIdx(idx);
    },
    [instanceRef]
  );

  return (
    <div className="w-full bg-primary">
      <div className="container px-0">
        {title && (
          <h2 className="text-2xl from-tablet:text-4xl font-medium pt-6 text-center text-secondary pb-5">
            {title}
          </h2>
        )}
        <div
          ref={sliderRef}
          className="keen-slider flex w-full overflow-hidden"
        >
          {items.map(({ image, text }, index) => {
            return (
              <div
                key={index}
                data-quotes-item
                className="relative pb-8 from-desktop:pb-16"
              >
                <div className="flex flex-col from-desktop:flex-row gap-5 p-5 from-desktop:p-8 bg-secondary text-primary h-full">
                  <div className="w-full from-desktop:w-1/4 aspect-h-1 relative max-h-[300px]">
                    <div className="absolute top-0 left-0 w-full h-full transition-all">
                      {image && (
                        <Image
                          src={image}
                          alt=""
                          fill
                          className="object-contain"
                        />
                      )}
                    </div>
                  </div>
                  <div className="w-full from-desktop:w-3/4">
                    {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
                  </div>
                </div>
                <div className="absolute bottom-0 left-8">
                  <div
                    className={cx(
                      "w-0 h-0",
                      "border-l-[16px] from-desktop:border-l-[30px] border-l-transparent",
                      "border-t-[32px] from-desktop:border-t-[64px] border-t-secondary",
                      "border-r-[16px] from-desktop:border-r-[30px] border-r-transparent"
                    )}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex gap-2 w-full items-center justify-center my-3 h-5 only-mobile:px-5">
          {items.map((_, index) => {
            const isActive = index === currentSlide;
            return (
              <div
                key={index}
                onClick={() => {
                  onThumbClick(index);
                }}
                className={cx(
                  "transition-all cursor-pointer only-mobile:flex-1 only-mobile:h-[10px]",
                  isActive
                    ? "w-5 h-5 bg-action"
                    : "w-[10px] h-[10px] bg-secondary"
                )}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
